@tailwind base;
@tailwind components;
@tailwind utilities;

*:before,
*:after {
    box-sizing: initial;
}

body {
    font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

@layer base {
    @font-face {
        font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
    }

    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }

    /*TW по-умолчанию устанавливает всем элементам border-box, что иногда ломает китовые UI-компоненты*/
    *, ::before, ::after {
        box-sizing: unset;
    }

    /* Ломает компонент CarNumber */
    input {
        line-height: normal;
    }
}

.glz-popup-footer {
    padding: 24px 34px;
}
