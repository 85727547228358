.main-score-container {
 .stars-container:hover  ,
 .stars-container.active {
  & > svg path {
   fill: #64B82A;
  }
 }
}

.main-score-container.score-1 {
 .stars-container:hover  ,
 .stars-container.active {
  & > svg path {
   fill: #D0021B;
  }
 }
}

.main-score-container.score-2,
.main-score-container.score-3 {
 .stars-container:hover  ,
 .stars-container.active {
  & > svg path {
   fill: #DBA92E;
  }
 }
}