.h3 {
    color: #8c969d;
    font-family: Verdana;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
    text-transform: uppercase;
}

